<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>결제 이력 보기</li>
						</ul>
					</div>
					<h2 class="page_title">결제 이력 보기</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="account_info">
						<template v-if="!isLogin()"><b>로그인이 필요한 서비스입니다</b></template>
						<template v-else><b>{{ getSessionProc().emailId }}</b> 님께서 신청한 서비스 이용 정보입니다.</template>
					</div>
					<div class="tbl_board">
						<table>
							<thead>
							<tr>
								<th>No.</th>
								<th>청구 항목</th>
								<th>청구 금액</th>
								<th>요금 청구월</th>
								<th>납부 일자</th>
							</tr>
							</thead>
							<tbody>
							<!-- 리스트 없을경우-->
							<template v-if="!isLogin() || historyList.length<0">
								<tr>
									<td colspan="7" class="content_none">등록된 컨텐츠가 없습니다.</td>
								</tr>
							</template>
							<!-- 리스트 있을경우-->
							<template v-else>
								<tr v-for="(item, index) in historyList" @click="goForm(item.zidx)" :key="'h1-'+index">
									<td data-td="No">{{ startIndex + index + 1 }}</td>
									<td data-td="청구 항목">
										<a href="#">
											{{ item.channelList | stringToArrayForService }}
											<template v-if="item.type === 'cancel'">(결제취소)</template>
										</a>
									</td>
									<td data-td="청구 금액">
										<template v-if="item.type === 'bill'">
											<template v-if="item.channelList === 'infra' || item.channelList === 'add_proj' || item.channelList === 'consulting'">
												{{ item.addPrice }}
											</template>
											<template v-else>
												{{ item.monthPrice }}
											</template>
										</template>
										<template v-else-if="item.type === 'cancel'">
											{{ item.realPayPrice }}
										</template>
										<template v-else/>
									</td>
									<td data-td="요금 청구월">{{ item.month }}</td>
									<td data-td="납부 일자">{{ item.paymentDate | dateFilter }}</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>

					<div class="pagination">
						<!-- TODO 이전페이지 다음페이지 이미지 어디갔담..? 수정 필요 -->
						<a href="#" class="prev"><span class="blind">이전 페이지</span></a>
						<a v-for="(item,index) in totalPage"  :key="'t1-'+index" href="#" @click="goPage(item-1)" :class="{ 'current': item == (page +1) }">{{item}}</a>
						<a href="#" class="next"><span class="blind">다음 페이지</span></a>
					</div>

				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "historyList",
	data() {
		return {
			historyList: [],
			page: 0,
			count: 0,
			size: 8,

			totalPage: 0,
			totalCount: 0
		}
	},
	computed: {
		// 시작 인덱스 계산
		startIndex() {
			return this.page * this.size;
		}
	},
	mounted() {
		this.getMyHistoryList()
	},
	methods: {
		goForm(_idx) {
			location.href='/myService/historyView?idx=' + _idx
		},

		getMyHistoryList() {

			const self = this;

			if( this.isLogin() ) {

				this.$eventBus.$emit("startProgress")

				var temp = JSON.parse(JSON.stringify(this.$route.params))
				temp.size = this.size || 8
				temp.page = this.page || 0
				temp.emailId = this.getSessionProc().emailId;

				this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyHistoryList", temp, (res) => {
					var result = res.data
					if (result.status === "ok") {
						self.historyList = result.list.content;
						self.totalCount = result.list.totalElements;
						self.totalPage = result.list.totalPages;
					} else {
						this.swalUtils.gritter("Error", result.msg, "error")
					}
					this.$eventBus.$emit("doneProgress")
				})

			}

		},

		goPage(page) {

			this.page = page;
			this.getMyHistoryList();
		}
	}
}
</script>

<style scoped>

</style>